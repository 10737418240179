var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-secondary', {
          attrs: {
            "itemTitle": "청약신청",
            "bg": "/images/form/application/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약신청")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "order-lg": "2"
    }
  }, [_c('v-card', {
    staticClass: "pa-24px px-md-40px py-md-84px",
    attrs: {
      "tile": ""
    }
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm"
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--xs"
  }, [_c('tit', {
    staticClass: "font-secondary secondary--text"
  }, [_vm._v("Lumino816")])], 1), _c('tit', [_vm._v("루미노816 청약신청 접수/조회")])], 1), _c('tit-wrap', {
    staticClass: "tit-wrap--sm"
  }, [!_vm.isClosed ? _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('application-dialog', {
    ref: "applicationDialog",
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          staticClass: "white--text v-btn--width-fixed",
          attrs: {
            "tile": "",
            "x-large": "",
            "color": "black"
          },
          on: {
            "click": _vm.blockUntilOpen
          }
        }, 'v-btn', attrs, false), [_c('span', {
          staticClass: "font-size-14 font-size-lg-16"
        }, [_vm._v("청약신청/조회")])])];
      }
    }], null, false, 1993426506)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "tile": "",
      "x-large": "",
      "color": "secondary",
      "to": "/subscription/recruitment"
    }
  }, [_c('span', {
    staticClass: "font-size-14 font-size-lg-16"
  }, [_vm._v("모집공고 다운로드")])])], 1)], 1) : _c('div', {
    staticClass: "tit"
  }, [_vm._v("공지사항을 확인해 주세요.")])], 1), _c('sheet-primary', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt', {
    staticClass: "font-weight-bold txt--dark"
  }, [_vm._v("주택위치")])], 1), _c('v-col', [_c('txt', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" 서울특별시 은평구 불광동 302-13번지 일원 "), _c('br'), _vm._v(" (3호선, 6호선 연신내역 3번 출구) ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt', {
    staticClass: "font-weight-bold txt--dark"
  }, [_vm._v("공급호수")])], 1), _c('v-col', [_c('txt', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" 총 299세대 중 공공지원 민간임대 180세대 "), _c('br'), _vm._v(" (특별공급 36세대, 일반공급 144세대) ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "588",
      "src": "/images/sub/application/application.png"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }